<script lang="ts" setup>
// composable
const { t, currentLocale } = useLang();
const { isLoggedIn } = useUser();

function getStarted() {
  isLoggedIn
    ? navigateTo("/my-account/wishlists")
    : navigateTo("/auth/register");
}
// meta
definePageMeta({
  layout: "page",
  auth: false,
});

useSeoMeta({
  title: t("pages.index.title"),
  description: t("pages.index.description"),
  keywords: t("pages.index.keywords"),
  ogImage: "/assets/images/bg_light_old.svg",
  ogUrl: "https://tahadu.app",
  ogType: "website",
  ogLocale: currentLocale.value.iso.toString(),
  ogSiteName: t("app.name"),
  ogTitle: t("pages.index.title"),
  ogDescription: t("pages.index.description"),
  twitterCard: "summary_large_image",
  twitterSite: "@tahadu_app",
  twitterCreator: "تهادوا",
  twitterTitle: t("pages.index.title"),
  twitterDescription: t("pages.index.description"),
  twitterImage: "/assets/images/bg_light_old.svg",
});
</script>

<template>
  <PageWrapper>
    <PageBody>
      <!-- hero section -->
      <div class="flex-1 flex flex-col -mt-8">
        <div
          class="hero-bg flex flex-col justify-center items-center px-auto px-6 lg:px-0 lg:pt-4 lg:pr-4"
        >
          <h1
            class="md:text-5xl text-3xl font-bold tracking-tight text-center sm:mt-20 mt-20 text-[#47188A] dark:text-[#C7ADEB]"
          >
            {{ t("pages.index.page_heading") }}
          </h1>

          <p class="text-center mt-3 font-bold md:text-3xl text-1xl">
            {{ t("pages.index.page_subheading") }}
          </p>
          <div class="flex gap-x-4 mt-8 justify-center">
            <Button
              size="xl"
              :text="t('pages.index.buttons.get_started')"
              class="font-extrabold justify-center mb-20"
              @click="getStarted()"
            />
          </div>
        </div>
      </div>
      <!-- features section images -->
      <div class="overflow-hidden py-5 sm:py-6 bg-[#d2ddde]">
        <div class="md:px-6 lg:px-20 features-bg">
          <div
            class="grid grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-12 md:grid-cols-3 lg:grid-cols-3 lg:items-start"
          >
            <div class="container m-auto">
              <img
                class="mb-2 w-48 block mx-auto"
                src="/assets/images/privacy.png"
              />
              <p
                class="text-3xl text-center text-[#0B0515] font-bold align-middle"
              >
                {{ t("pages.index.picture_title.privacy") }}
              </p>
            </div>
            <div class="container m-auto">
              <img
                class="mb-2 w-48 block mx-auto"
                src="/assets/images/satisfaction.png"
              />
              <p
                class="text-3xl text-center text-[#0B0515] font-bold align-middle"
              >
                {{ t("pages.index.picture_title.satisfaction") }}
              </p>
            </div>
            <div class="container m-auto">
              <img
                class="mb-2 w-48 block mx-auto"
                src="/assets/images/wanted_gifts.png"
              />
              <p
                class="text-3xl text-center text-[#0B0515] font-bold align-middle"
              >
                {{ t("pages.index.picture_title.wanted_gifts") }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- steps section -->
      <div class="overflow-hidden py-24 sm:py-32">
        <div class="mx-auto max-w-7xl md:px-6 lg:px-16">
          <div
            class="grid grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:grid-cols-2 lg:items-start"
          >
            <div class="px-6 lg:px-0 lg:pt-4 lg:pr-4">
              <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
                <h2
                  class="text-2xl font-semibold leading-7 text-[#47188A] dark:text-[#C7ADEB]"
                >
                  {{ t("pages.index.steps_heading") }}
                </h2>
                <p class="mt-2 text-3xl font-bold tracking-tight sm:text-4xl">
                  {{ t("pages.index.steps_subheading") }}
                </p>
                <dl
                  class="mt-10 max-w-xl space-y-8 text-lg leading-7 lg:max-w-none"
                >
                  <div class="relative pl-9">
                    <dt class="inline font-semibold">
                      <Icon
                        v-if="currentLocale.dir === 'ltr'"
                        name="heroicons:chevron-right-solid"
                        class="absolute top-1 left-1 h-5 w-5"
                        aria-hidden="true"
                      />
                      <Icon
                        v-else
                        name="heroicons:chevron-left-solid"
                        class="absolute top-1 right-1 h-5 w-5"
                        aria-hidden="true"
                      />
                      <Anchor
                        to="/auth/register"
                        class="pr-8 underline hover:no-underline"
                      >
                        {{ t("pages.index.steps_list.step_one") }}</Anchor
                      >
                    </dt>
                  </div>
                  <div class="relative pl-9">
                    <dt class="inline font-semibold">
                      <Icon
                        v-if="currentLocale.dir === 'ltr'"
                        name="heroicons:chevron-right-solid"
                        class="absolute top-1 left-1 h-5 w-5"
                        aria-hidden="true"
                      />
                      <Icon
                        v-else
                        name="heroicons:chevron-left-solid"
                        class="absolute top-1 right-1 h-5 w-5"
                        aria-hidden="true"
                      />
                      <p class="pr-8">
                        {{ t("pages.index.steps_list.step_two") }}
                      </p>
                    </dt>
                  </div>
                  <div class="relative pl-9">
                    <dt class="inline font-semibold">
                      <Icon
                        v-if="currentLocale.dir === 'ltr'"
                        name="heroicons:chevron-right-solid"
                        class="absolute top-1 left-1 h-5 w-5"
                        aria-hidden="true"
                      />
                      <Icon
                        v-else
                        name="heroicons:chevron-left-solid"
                        class="absolute top-1 right-1 h-5 w-5"
                        aria-hidden="true"
                      />
                      <p class="pr-8">
                        {{ t("pages.index.steps_list.step_three") }}
                      </p>
                    </dt>
                  </div>
                  <div class="relative pl-9">
                    <dt class="inline font-semibold">
                      <Icon
                        v-if="currentLocale.dir === 'ltr'"
                        name="heroicons:chevron-right-solid"
                        class="absolute top-1 left-1 h-5 w-5"
                        aria-hidden="true"
                      />
                      <Icon
                        v-else
                        name="heroicons:chevron-left-solid"
                        class="absolute top-1 right-1 h-5 w-5"
                        aria-hidden="true"
                      />
                      <p class="pr-8">
                        {{ t("pages.index.steps_list.step_four") }}
                      </p>
                    </dt>
                  </div>
                </dl>
              </div>
            </div>
            <div class="sm:px-6 lg:px-0">
              <div
                class="relative isolate overflow-hidden bg-[#47188A] px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pt-16 sm:pl-16 sm:pr-0 lg:mx-0 lg:max-w-none shadow-lg"
              >
                <div
                  class="absolute transform -inset-y-5 -left-3 -z-10 w-full origin-bottom-left -skew-x-[52deg] bg-[#C7ADEB]"
                  aria-hidden="true"
                ></div>
                <div class="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                  <img
                    v-if="currentLocale.dir === 'rtl'"
                    src="/assets/images/steps_ar.png"
                    alt="Product screenshot"
                    class="-mb-12 w-[57rem] rounded-tl-3xl"
                  />
                  <img
                    v-else
                    src="/assets/images/steps_en.png"
                    alt="Product screenshot"
                    class="-mb-12 w-[57rem] rounded-tl-3xl"
                  />
                </div>
                <div
                  class="pointer-events-none absolute sm:rounded-3xl"
                  aria-hidden="true"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageBody>
  </PageWrapper>
</template>

<style lang="scss">
@import "../assets/scss/_variables.scss";

@keyframes anim-fg-1 {
  0%,
  16.667%,
  100% {
    opacity: 1;
  }

  33.333%,
  83.333% {
    opacity: 0;
  }
}

@keyframes anim-fg-2 {
  0%,
  16.667%,
  66.667%,
  100% {
    opacity: 0;
  }

  33.333%,
  50% {
    opacity: 1;
  }
}

@keyframes anim-fg-3 {
  0%,
  50%,
  100% {
    opacity: 0;
  }

  66.667%,
  83.333% {
    opacity: 1;
  }
}

.animated-text-bg {
  position: relative;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  content: var(--content);
  display: block;
  width: 100%;
  color: theme("colors.slate.800");
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  padding-left: $padding;
  padding-right: $padding;
  &:before {
    content: var(--content);
    position: absolute;
    display: block;
    width: 100%;
    color: theme("colors.slate.800");
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    padding-left: $padding;
    padding-right: $padding;
  }
}
.animated-text-fg {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-left: $padding;
  padding-right: $padding;
  background-image: linear-gradient(
    90deg,
    var(--start-color),
    var(--end-color)
  );
  position: relative;
  opacity: 0;
  z-index: 1;
  animation: var(--animation-name) 8s infinite;
}

html.dark {
  .animated-text-bg {
    color: theme("colors.gray.100");
    &:before {
      color: theme("colors.gray.100");
    }
  }
  .hero-bg {
    position: relative;
  }
  .hero-bg::before {
    content: "";
    background: url("/assets/images/bg_dark.svg") round center center;
    background-size: contain;
    opacity: 0.2; /* set the desired opacity value */
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
}
.triangle-shape {
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 40px solid theme("colors.green.600");
  transform: translate(-15rem, -6rem) rotate(45deg);
}

.hero-bg {
  position: relative;
}
.hero-bg::before {
  content: "";
  background: url("/assets/images/bg_light.svg") round center center;
  background-size: contain;
  opacity: 1; /* set the desired opacity value */
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}
.features-bg {
  background-image: url("/assets/images/right-half-logo.svg"),
    url("/assets/images/left-half-logo.svg");
  background-position:
    left center,
    right center;
  background-repeat: no-repeat, no-repeat;
  background-size: contain;
  background-size:
    auto 180px,
    auto 180px;
}
</style>
